import React from "react"
import { FooterPageLayout } from "../components/Global/Layouts"

const RefundPage = () => (
  <FooterPageLayout
    type={"refund"}
    title={"Refund Policy"}
    description={"The refund policy page :o"}
    query={"query { shop { refundPolicy { body } } }"}
  />
)

export default RefundPage
